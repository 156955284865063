import React, { useEffect, useState } from "react";
import { Carousel } from "react-carousel-minimal";
import "./App.css";

export default function App() {
  const captionStyle = { display: "none" };

  // const slideNumberStyle = {
  //   fontSize: "20px",
  //   fontWeight: "bold",
  // };

  const [images, setImages] = useState([]);

  const captureParam = async() => {
    debugger
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const data = urlParams.get("data");
    const imgs = await JSON.parse(data)
    imgs && setImages(() => imgs);
  }

  useEffect(() => {
    captureParam();
  }, []);

  return (
    <div className="App">
      {images.length > 0 && (
        <div style={{ textAlign: "center" }}>
          <div>
            <Carousel
              data={images}
              width="100%"
              height="600px"
              captionStyle={captionStyle}
              slideNumber={false}
              captionPosition="bottom"
              dots={true}
              pauseIconColor="white"
              pauseIconSize="40px"
              slideBackgroundColor="darkgrey"
              slideImageFit="cover"
              thumbnails={true}
              thumbnailWidth="150px"
              style={{
                textAlign: "center",
                maxWidth: "1600px",
                maxHeight: "500px",
                margin: "40px auto",
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}
